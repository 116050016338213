
/**
  * bas64 文字列になっている ZIP ファイル(バイナリデータ) をバイナリデータに変換する
  *
  * @private
  * @param {string} base64 バイナリデータを base64 エンコードして更に文字列化した文字列
  * @returns {Blob} 引数の文字列をバイナリに戻したバイナリデータ
  * @memberof AggregateMonthlyComponent
  * @description
  *  ZIP ファイルへの変換のみ対応している
  * @see
  *  https://developer.mozilla.org/ja/docs/Web/API/WindowBase64/atob
  *  https://developer.mozilla.org/ja/docs/Web/API/Blob
  *  https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_objects/Uint8Array
  */
//function toBlobZip(base64: string): Blob {
const toBlobZip = (base64: string): Blob => {
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([buffer.buffer], {
        type: 'application/zip'
    });
    return blob;
}


export default toBlobZip