import React from "react";
import Button from "@mui/material/Button";
//import CircularProgress from "@mui/material/CircularProgress";
import type * as CSS from 'csstype';
import { DropzoneRootProps, DropzoneInputProps, FileWithPath } from 'react-dropzone';
import { useMemo } from 'react';


type Props = {
    handleOnSubmit: (e: React.SyntheticEvent) => Promise<void>,
    handleOnAddFile: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleOnCancel: () => void,
    inputId: string,
    files: File[],
    maxFilesUpload: number,
    isSending: boolean,
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T,
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T,
    isDragActive: boolean,
    open: () => void,
    acceptedFiles: FileWithPath[],
    progress: number,
    style?: CSS.Properties,


}

const FileUploader: React.FC<Props> = ({
    handleOnSubmit,
    handleOnAddFile,
    handleOnCancel,
    inputId,
    files,
    maxFilesUpload,
    isSending,
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    acceptedFiles,
    progress,
    style,

}) => {

    // drug&dropのスタイル設定
    const baseStyle = {
        // border: "1px dotted #888"
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        width: 500,
        height: 200,
        backgroundColor: "#f5f5f5",
        border: "2px dashed  #d3d3d3",

    };
    const borderNormalStyle = {
        border: "2px dashed  #d3d3d3"
    };
    const borderDragStyle = {
        border: "2px solid #00f",
        transition: 'border .5s ease-in-out'
    };
    const styleDD = useMemo(() => (
        { ...baseStyle, ...(isDragActive ? borderDragStyle : borderNormalStyle) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [isDragActive]);

    const styleLi = {
        margin: "1%"
    }
    const hidden = {
        display: "none"
    }
    const styleP = {
        color: "#a9a9a9"
    }


    const filesAccepted = useMemo(() =>
        acceptedFiles.map((file) => (
            <li key={file.path}>
                {file.path} - {file.size / 1000} kB
            </li>
        )
        ), [acceptedFiles]);


    return (
        <div>
            <form action="" onSubmit={(e) => handleOnSubmit(e)}>
                {/* drug & drop */}
                <div {...getRootProps()} style={styleDD}>
                    <input {...getInputProps()} />
                    <div>
                        {
                            isDragActive ?
                                <p style={styleP}>Drop the file here ...</p> :
                                <p style={styleP}>Drag 'n' drop a midi file here</p>
                        }
                        <Button
                            variant="contained"
                            disabled={files.length >= maxFilesUpload}
                            component="span"
                            style={btnUpload}
                            onClick={open}
                        >
                            Upload .mid file
                        </Button>
                    </div>
                </div>

                {/* 選択されたファイル名を表示 */}
                <div
                    style={files.length >= maxFilesUpload ? styleLi : hidden}>
                    {filesAccepted}
                </div>


                {/* submitボタン */}
                <Button
                    variant="contained"
                    type="submit"
                    disableElevation
                    disabled={files.length === 0 || isSending}
                    style={btn}
                >
                    Submit
                </Button>
            </form>

        </div>
    )
}

const btn: React.CSSProperties = {
    textTransform: "none",
    margin: "1%"
}
const btnUpload: React.CSSProperties = {
    textTransform: "none",
}




export default FileUploader