import React, { useState } from "react";
import axios from "axios";
import fileDownload from 'js-file-download';

import type * as CSS from 'csstype';
import Button from "@mui/material/Button";
import { useCallback } from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import UUID from 'uuidjs';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from "./App.module.css";
import Header from "./components/Header";
//import AudioPlayer from "./components/AudioPlayer";
import FileUploader from "./components/FileUploader";
import toBlobZip from "./utils/base64";
import usePageTracking from "./utils/useTracking";


type ResponseUpload = {
  name: string[];
  zip: string;
  canceled: boolean;
}

type ResponseProgress = {
  progress: number;
  canceled: boolean;
}

type ResponseCancel = {
  result: string;
}
let id: string = UUID.generate();

export default function App() {
  usePageTracking();


  const urlOrigin: string | undefined = process.env.REACT_APP_URL_ORIGIN;
  const [isSending, setIsSending] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const maxFilesUpload = 1; // 画像を最大n枚まで選択・アップロード
  const inputId = Math.random().toString(32).substring(2);
  const [base64Zip, setBase64Zip] = useState<string>("");
  const [nameZip, setNameZip] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  //const [id, setId] = useState<string>(UUID.generate());



  const headerFormData = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }

  let progressBar = isSending ? (
    <div
      style={styleProgress}
    >
      <ProgressBar
        now={progress}
        label={`${progress}%`}
      />
    </div>) : null;


  // drug&drop
  const onDrop = useCallback((files: File[]) => {
    // Do something with the files
    //console.log('accepted files:', files);
    setFiles([...files, ...files]);
    //初期化
    initStates();
  }, [])

  const acceptType: Accept = {
    'audio/midi': ['.mid', '.midi']
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    acceptedFiles
  } = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: 1,
    accept: acceptType,
    disabled: isSending,
  });


  function initStates(): void {
    //初期化
    setBase64Zip("");
    setNameZip("");
    setProgress(0);
    //setId(UUID.generate());
  }


  // progress問い合わせ
  function getProgress(): void {

    const data = new FormData();
    data.append("id", id);

    const url: string = urlOrigin + "progress";
    const interval: number = 5000;
    let prog: number = 0;
    let canceled: boolean = false;
    let timer: ReturnType<typeof setInterval> = setInterval(async () => {
      const response = await axios.post<ResponseProgress>(url, data, headerFormData);

      prog = response.data.progress;
      setProgress(prog);
      canceled = response.data.canceled;

      //console.log(prog);
      if (prog >= 100 || canceled) {
        //console.log("progress completed");
        clearInterval(timer);
      }
    }, interval);

  }


  const rejectAfterDelay = (ms: number): Promise<void> => new Promise<void>((_, reject) => {
    setTimeout(reject, ms, new Error("timeout"));
  });


  const requestCancel = async (): Promise<void> => {
    const data = new FormData();
    data.append("id", id);
    const url: string = urlOrigin + "cancel";
    await axios.post<ResponseCancel>(url, data, headerFormData);
    //console.log(response.data.result);
    setFiles([]);
    setIsSending(false);

  };


  // upload処理
  const handleOnSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setIsSending(true);

    //初期化
    initStates();

    const date = new Date();
    //setId(id + "-" + date.getTime());
    id = UUID.generate() + "-" + date.getTime()
    //console.log("date.getTime()", date.getTime());

    const data = new FormData();
    files.forEach((file) => {
      data.append("midiFile", file);
      setNameZip(file.name);
    });
    data.append("id", id);

    const sleep = new Promise(resolve => setTimeout(resolve, 5000));
    //console.log("id", id);
    // ファイルを送信
    const url: string = urlOrigin + "upload";
    const timeout: number = 1000 * 900;
    const responses = await Promise.allSettled([
      Promise.race([
        axios.post<ResponseUpload>(url, data, headerFormData),
        rejectAfterDelay(timeout),
      ]),
      Promise.race([
        Promise.resolve<void>(sleep.then(() => getProgress())),
        rejectAfterDelay(timeout),
      ]),
    ])


    setIsSending(false);
    // 結果を格納
    //console.log(responses);
    const response = responses[0];

    // zipファイル
    if (response.status === 'fulfilled' && response.value instanceof Object) {
      if ("zip" in response.value.data) {
        // 正常終了
        let zipAudio: string = response.value.data.zip;
        setBase64Zip(zipAudio);
      }
      if (response.value.data.canceled) {
        // キャンセル
        alert("Request Canceled");
      }
    } else { //if (response.status === 'rejected') {
      // タイムアウト
      alert("Timeout Occured");
      requestCancel();
    }


    // 後処理
    setFiles([]);

  };

  const handleOnAddFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    //console.log("handleOnAddFile");

    if (!e.target.files) return;
    setFiles([...files, ...e.target.files]);

    //初期化
    initStates();
  };

  const handleOnDownload = (): void => {
    // ダウンロード処理
    // zip単体
    const name: string = nameZip.replace(/\.[^/.]+$/, "") + ".zip";
    fileDownload(toBlobZip(base64Zip), name);

  }

  return (

    <div
      className={styles.mainOuter}
    >
      <div
        className={styles.mainInner}
      >
        <Header
          style={header}
        />

        {/* アップロード */}
        <FileUploader
          handleOnSubmit={handleOnSubmit}
          handleOnAddFile={handleOnAddFile}
          handleOnCancel={requestCancel}
          inputId={inputId}
          files={files}
          maxFilesUpload={maxFilesUpload}
          isSending={isSending}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
          open={open}
          acceptedFiles={acceptedFiles}
          progress={progress}
        />

        <div style={divDownload}>
          {/* progress bar */}
          {progressBar}

          {/* cancelボタン */}
          <Button
            variant="contained"
            type="submit"
            disableElevation
            disabled={!isSending}
            color="warning"
            style={btnCancel}
            onClick={(event) => {
              requestCancel();
            }}
          >
            Cancel
          </Button>

          {/* ダウンロードボタン */}
          <Button
            variant="contained"
            type="submit"
            onClick={handleOnDownload}
            disabled={base64Zip.length === 0}
            style={btnDownload}
          >
            Download .zip file
          </Button>

        </div>
      </div>
    </div >
  );
};


const header: CSS.Properties = {
  margin: "5%"
}

const divDownload = {
  width: "300px",
  margin: "1% auto",
}
const styleProgress = {
  margin: "5% auto",
  width: "75%"
}

const btnCancel: React.CSSProperties = {
  textTransform: "none",
  margin: "1%"
}
const btnDownload: React.CSSProperties = {
  margin: "1%",
  textTransform: "none",
}