import React from "react";
import type * as CSS from 'csstype';

type Props = {
    style?: CSS.Properties;
}
const Header: React.FC<Props> = ({ style }) => {

    return (
        <div
            style={style}
        >
            <h1>
                midi-to-wav
            </h1>
            <ul
                style={ul}>
                <li>Upload a .mid file.</li>
                <li>We extract tracks in the .mid file and generate audio file for each of them.</li>
            </ul>

        </div>

    )



};

const ul = {
    listStyleType: "none",
    padding: "0",
    margin: "0",
}

export default Header